import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";

function NotFound() {
  return (
    <Card>
      <CardContent>
        <Typography>404 NOT FOUND!</Typography>
      </CardContent>
    </Card>
  );
}

export default NotFound;
