import React from "react";
import "firebase/auth";
import "./PricingPage.css";
import { EditorContainer } from "../../components/EditorContainer";

export const PricingPage = () => {
  return (
    <div>
      <EditorContainer title={"App Fee"} id={'appFee'} />
      {/* <EditorContainer title={"Services"} id={'categories'} /> */}
      {/* <EditorContainer title={"FAQ"} id={'faq'} /> */}
    </div>
  );
};

export default PricingPage;
