import React, { useEffect, useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import "firebase/auth";
import {
  Grid,
  Typography,
  Avatar,
  GridList,
  ButtonBase,
  Backdrop,
  Button,
} from "@material-ui/core";
import { FirebaseContext } from "../components/Firebase";
import { useContext } from "react";
import "firebase/firestore";
import { FirebaseFirestore, DocumentData } from "@firebase/firestore-types";
import Account, { createAccount } from "../interfaces/account";
import { useHistory } from "react-router-dom";
import { usersRef } from "../services/firebase";

const styles = (theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 650,
    },
    buttonDecline: {
      backgroundColor: "#000",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#000",
        color: "#ffffff",
      },
      marginRight: "10px",
      marginLeft: "10px",
    },
    buttonAccept: {
      backgroundColor: "#FDC032",
      color: "#000",
      "&:hover": {
        backgroundColor: "#FDC032",
        color: "#000",
      },
      marginRight: "10px",
      marginLeft: "10px",
    },
    buttons: {
      margin: "1rem",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  });

export interface ContentProps extends WithStyles<typeof styles> {}

function ApplicantField(props: any) {
  const { label, value } = props;

  const getBooleanString = (value: boolean) => {
    return value ? "Yes" : "No";
  };
  return (
    <Grid
      container
      alignContent="space-between"
      direction="row"
      wrap="wrap"
      spacing={3}
    >
      <Grid item sm={2}>
        <Typography align="left">{label}</Typography>
      </Grid>
      <Grid item sm={8}>
        <Typography align="left">
          {typeof value === "boolean" ? getBooleanString(value) : value}
        </Typography>
      </Grid>
    </Grid>
  );
}

function ApplicantsPage(props: any) {
  const { classes } = props;
  const db = useContext(FirebaseContext) as FirebaseFirestore;
  const [loading, setLoading] = useState(true);
  const [applicant, setApplicant] = useState<Account | any>();
  const [image, setImage] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    const applicant = usersRef
      .doc(props.match.params.id)
      .onSnapshot((doc: DocumentData) => {
        if (doc !== undefined) {
          setApplicant(createAccount(doc.data()));
          setLoading(false);
        }
      });
    return () => {
      applicant();
    };
  }, [db, props.match.params]);

  const accept = () => {
    usersRef.doc(props.match.params.id).update({ licenseVerified: true });
    history.goBack();
  };

  const decline = () => {
    usersRef.doc(props.match.params.id).update({ licenseVerified: false });
    history.goBack();
  };

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={image.length > 0}
        onClick={() => setImage("")}
      >
        <img src={image} alt={image} />
      </Backdrop>
      {loading || applicant === undefined ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <Grid container direction="column" justify="flex-start">
            <Grid item>
              <Avatar
                className={classes.profileImage}
                alt={applicant.displayName}
                src={applicant.photoUrl}
                style={{ width: "150px", height: "150px" }}
              />
            </Grid>
            <Grid item>
              <h2>{applicant.displayName}</h2>
            </Grid>
          </Grid>
          <hr />
          <ApplicantField label="Phone Number" value={applicant.phoneNumber} />
          <ApplicantField
            label="Phone Verified"
            value={applicant.phoneNumberVerified}
          />
          <ApplicantField label="Email" value={applicant.email} />
          <ApplicantField
            label="Location"
            value={applicant.location.formattedAddress}
          />
          <ApplicantField
            label="Created At"
            value={`${new Date(
              applicant.createdAt["seconds"] * 1000
            ).toLocaleTimeString()} on ${new Date(
              applicant.createdAt["seconds"] * 1000
            ).toLocaleDateString()}`}
          />
          <ApplicantField label="About" value={applicant.about} />
          <hr />
          {applicant.licenses != null ? (
            <GridList>
              {applicant.licenses.map((photo: any) => {
                return (
                  <ButtonBase
                    key={photo}
                    onClick={() => setImage(photo)}
                    style={{ height: "150px", width: "150px", margin: "10px" }}
                  >
                    <img
                      src={photo}
                      alt={photo}
                      style={{ height: "150px", width: "150px" }}
                    />
                  </ButtonBase>
                );
              })}
            </GridList>
          ) : null}
        </div>
      )}
      <div>
        <Grid className={classes.buttons} container direction="row">
          <Button className={classes.buttonAccept} onClick={accept}>
            Accept
          </Button>
          <Button className={classes.buttonDecline} onClick={decline}>
            Decline
          </Button>
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(ApplicantsPage);
