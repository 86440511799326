import { DocumentData } from '@firebase/firestore-types';

export default interface Constants {
    hairAdditions: ServiceAddition[],
    makeupAdditions: ServiceAddition[],
    nailAdditions: ServiceAddition[],
    hairLength: string[],
    hairServices: Service[];
    makeupServices: Service[];
    nailServices: Service[];
}

export interface Service {
    cost: number;
    label: string;
    specialty: string;
}

export interface ServiceAddition {
    style: string;
    cost: number;
    code: string;
}

export function createConstants(data: DocumentData): Constants {
    return {
        hairAdditions: data.hairAdditions,
        makeupAdditions: data.makeupAdditions,
        nailAdditions: data.nailAdditions,
        hairLength: data.hairLength,
        hairServices: data.hairServices,
        makeupServices: data.makeupServices,
        nailServices: data.nailServices,
    };
}