import React from "react";
import { Switch, Route } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import ApplicantPage from "../../pages/ApplicantPage";
import ApplicantsPage from "../../pages/ApplicantsPage";
import BeauticiansPage from "../../pages/BeauticiansPage";
import BookingsPage from "../../pages/BookingsPage";
import NotificationsPage from "../../pages/NotificationsPage";
import PoliciesPage from "../../pages/PoliciesPage/PoliciesPage";
import AdminsPage from "../../pages/AdminsPage/AdminsPage";
import PricingPage from "../../pages/PricingPage/PricingPage";

function Content() {
  return (
    <React.Fragment>
      <Switch>
        <Route>
          <Route
            exact
            path={`/home${ROUTES.APPLICANTS}`}
            component={ApplicantsPage}
          />
          <Route
            exact
            path={`/home${ROUTES.BEAUTICIANS}`}
            component={BeauticiansPage}
          />
          <Route
            exact
            path={`/home${ROUTES.PRICING}`}
            component={PricingPage}
          />
          <Route
            exact
            path={`/home${ROUTES.BOOKINGS}`}
            component={BookingsPage}
          />
          <Route
            exact
            path={`/home${ROUTES.POLICIES}`}
            component={PoliciesPage}
          />
          <Route
            exact
            path={`/home${ROUTES.NOTIFICATIONS}`}
            component={NotificationsPage}
          />
          <Route
            exact
            path={`/home${ROUTES.ADMINS}`}
            component={AdminsPage}
          />
          <Route
            exact
            path={`/home${ROUTES.APPLICANTS}/:id/:name`}
            component={ApplicantPage}
          />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default Content;
