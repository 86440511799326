import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import SecurityIcon from "@material-ui/icons/Security";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import jwt_decode from "jwt-decode";

let adminCategory = {
  id: "Overview",
  children: [
    { id: "Applicants", icon: <AssignmentIndIcon /> },
    { id: "Beauticians", icon: <PeopleIcon /> },
    { id: "Pricing", icon: <MonetizationOnIcon /> },
    { id: "Bookings", icon: <ShoppingCartIcon /> },
    { id: "Policies", icon: <SecurityIcon /> },
    { id: "Notifications", icon: <NotificationsActiveIcon /> },
  ],
};

const dashboardCategory = {
  id: "Dashboard Management",
  children: [{ id: "Admins", icon: <SettingsIcon /> }],
};

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: "rgba(255, 255, 255, 0.7)",
      "&:hover,&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
    },
    itemCategory: {
      backgroundColor: "#C8952C",
      boxShadow: "0 -1px 0 #FDC032 inset",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.black,
    },
    itemPrimary: {
      fontSize: "inherit",
      color: "#000000",
    },
    itemIcon: {
      minWidth: "auto",
      marginRight: theme.spacing(2),
      color: "#000000",
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });

export type Admin = {
  admin: boolean;
  superAdmin: boolean;
};

function Navigator(props: any) {
  const { classes, staticContext, location, ...other } = props;
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const signOut = async () => {
    await firebase.auth().signOut();
  };

  const user = firebase.auth().currentUser;

  useEffect(() => {
    if (user !== undefined) {
      firebase
        .auth()
        .currentUser?.getIdToken()
        .then((token) => {
          if (token !== undefined) {
            const decode: Admin = jwt_decode(token) as Admin;
            if (
              decode["superAdmin"] !== undefined &&
              decode["superAdmin"] === true
            ) {
              setIsSuperAdmin(true);
            }
          }
        });
    }
  }, [user]);

  return (
    <Drawer variant="permanent" {...other}>
      <MenuList disablePadding>
        <MenuItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          Glamzzy
        </MenuItem>
        <div key={adminCategory.id}>
          <MenuItem className={clsx(classes.item, classes.itemCategory)}>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              {adminCategory.id}
            </ListItemText>
          </MenuItem>
          {adminCategory.children.map(({ id: childId, icon }) => {
            return (
              <MenuItem
                component={Link}
                to={"/home/" + childId.toLowerCase()}
                key={childId}
                button
                className={clsx(
                  classes.item,
                  location.pathname.includes(childId.toLowerCase()) &&
                    classes.itemActiveItem
                )}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </MenuItem>
            );
          })}
          <Divider className={classes.divider} />
        </div>
        {isSuperAdmin && (
          <div key={dashboardCategory.id}>
            <MenuItem className={clsx(classes.item, classes.itemCategory)}>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                {dashboardCategory.id}
              </ListItemText>
            </MenuItem>
            {dashboardCategory.children.map(({ id: childId, icon }) => {
              return (
                <MenuItem
                  component={Link}
                  to={"/home/" + childId.toLowerCase()}
                  key={childId}
                  button
                  className={clsx(
                    classes.item,
                    location.pathname.includes(childId.toLowerCase()) &&
                      classes.itemActiveItem
                  )}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </MenuItem>
              );
            })}
            <Divider className={classes.divider} />
          </div>
        )}
        <MenuItem
          className={clsx(classes.item, classes.itemCategory)}
          onClick={() => signOut()}
        >
          <ListItemIcon className={classes.itemIcon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Logout
          </ListItemText>
        </MenuItem>
      </MenuList>
    </Drawer>
  );
}

export default withStyles(styles)(withRouter(Navigator));
