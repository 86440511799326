import { adminRef, firebaseConfig } from "../services/firebase";
import firebase from "firebase/app";
import "firebase/auth";

export const isUserAdmin = async function (userId: string): Promise<boolean> {
  const doc = await adminRef.where("uid", "==", userId).get();
  return doc.size === 1;
};

export const authenticate = async function (
  email: string,
  password: string
): Promise<boolean> {
  let success = false;
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await firebase.auth().signInWithEmailAndPassword(email, password);
    success = true;
  } catch (err) {
    // let errorCode = err.code;
    // let errorMessage = err.message;
    // TODO: show error message in error dialog
  }
  return success;
};

export const createUser = async function (
  email: string,
  password: string
): Promise<firebase.auth.UserCredential | null> {
  let user = null;
  try {
    var createAdminFirebase = firebase.initializeApp(
      firebaseConfig,
      "createAdmin"
    );
    user = await createAdminFirebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    createAdminFirebase.auth().signOut();
  } catch (err) {
    // let errorCode = err.code;
    // let errorMessage = err.message;
    // TODO: show error message in error dialog
  }
  return user;
};

export const removeUser = async function (uid: string) {
  try {
    await adminRef.doc(uid).delete();
  } catch (err) {
    // TODO: show error message in  error dialog
  }
};

export const userIsAuthenticated = function () {
  return firebase.auth().currentUser;
};

export const unauthenticate = function () {
  firebase.auth().signOut();
};
