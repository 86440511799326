import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
} from "@material-ui/core/styles";
import "firebase/auth";
import {
    TableContainer,
    TableHead,
    Table,
    TableCell,
    TableRow,
    TableBody,
    Button,
} from "@material-ui/core";
import "firebase/firestore";
import Account, { createAccount } from "../interfaces/account";
import { beauticiansSortOptions } from "../interfaces/sortOptions";
import Pagination from "./AdminsPage/Pagination";
import { profilesRef, usersRef } from "../services/firebase";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const styles = (theme: Theme) =>
    createStyles({
        table: {
            // minWidth: 650,
        },
        buttonBlock: {
            backgroundColor: "#000",
            color: "#ffffff",
            "&:hover": {
                backgroundColor: "#000",
                color: '#ffffff'
            },
            marginRight: '10px',
            marginLeft: '10px',
        },
        buttonUnblock: {
            backgroundColor: "#FDC032",
            color: "#000",
            "&:hover": {
                backgroundColor: "#FDC032",
                color: '#000'
            },
            marginRight: '10px',
            marginLeft: '10px',
        },
        center: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
    });

export interface ContentProps extends WithStyles<typeof styles> { }


function BeauticiansPage(props: ContentProps) {
    const { classes } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(true);
    const [field, setField] = useState<any>(Object.keys(beauticiansSortOptions)[0]);
    const [beauticians, setBeauticians] = useState<Array<Account>>([]);
    const descendingFields = ['blocked'];

    useEffect(() => {
        setField(Object.keys(beauticiansSortOptions)[1]);
    }, []);

    const block = (uid: string) => {
        usersRef.doc(uid).set({ 'blocked': true }, { merge: true });
        profilesRef.doc(uid).set({ 'blocked': false }, { merge: true });
    }

    const unblock = (uid: string) => {
        usersRef.doc(uid).set({ 'blocked': false }, { merge: true });
        profilesRef.doc(uid).set({ 'blocked': false }, { merge: true });
    }
    return (
        <div>
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">Sort By</InputLabel>
                    <Select
                        native
                        value={field}
                        onChange={(e) => setField(e.target.value)}
                    >
                        {Object.entries(beauticiansSortOptions).map(([key, value]) => <option key={key} value={key}>{value}</option>)}
                    </Select>
                </FormControl>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>Beautician Id</TableCell> */}
                            <TableCell align="right">Beautician Name</TableCell>
                            <TableCell align="right">Phone Number</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Phone Verified</TableCell>
                            <TableCell align="right">Allowing Notifications</TableCell>
                            <TableCell align="right">Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {beauticians.map((beautician) => (
                            <TableRow key={beautician.uid}>
                                {/* <TableCell component="th" scope="row">
                                    {beautician.uid}
                                </TableCell> */}
                                <TableCell align="right">{beautician.displayName}</TableCell>
                                <TableCell align="right">{beautician.phoneNumber}</TableCell>
                                <TableCell align="right">{beautician.email}</TableCell>
                                <TableCell align="right">{beautician.phoneNumberVerified ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="right">{beautician.fcmToken != null ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="right">{beautician.location.formattedAddress}</TableCell>
                                <TableCell align="right">
                                    {
                                        beautician.blocked === undefined || !beautician.blocked ?
                                            <Button className={classes.buttonBlock} onClick={() => block(beautician.uid)}>Block</Button>
                                            :
                                            <Button className={classes.buttonUnblock} onClick={() => unblock(beautician.uid)}>Unblock</Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                collection={usersRef.where('mode', '==', 'professional').where('licenseVerified', '==', true)}
                setLoading={setLoading}
                setData={setBeauticians}
                field={field}
                descendingFields={descendingFields}
                createObject={createAccount} />
        </div>
    );
}

export default withStyles(styles)(BeauticiansPage);