import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { adminRef } from "../../services/firebase";
import { createUser } from "../../services/auth";
import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  Button,
  Modal,
} from "@material-ui/core";
import "./AdminsPage.css";
import firebase from "firebase/app";
import jwt_decode from "jwt-decode";
import Admin from "../../interfaces/admin";
import { Admin as AdminLevel } from "../../components/Navigator/index";
import TablePagination from "@material-ui/core/TablePagination";

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    paper: {
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    focused: {
      color: theme.palette.error.light,
    },
    row: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
      marginTop: theme.spacing(2),
    },
    confirmRemovalButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      "&:disabled": {
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[300],
      },
      "&:hover": {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
      },
    },
    center: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  });

function createData(name: string, email: string, id: string) {
  return { name, email, id };
}

export interface ContentProps extends WithStyles<typeof styles> {}

const nullAdmin = { name: "", email: "", id: "" };

function AdminsPage(props: any) {
  const { classes } = props;
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rows, setRows] = useState<Array<any>>([]);
  const [removing, setRemoving] = useState<Admin>(nullAdmin);
  const [confirmName, setConfirmName] = useState<string>("");

  const user = firebase.auth().currentUser;
  useEffect(() => {
    const fetchAdmins = async () => {
      let admins: any = [];
      adminRef.onSnapshot((snapshot) => {
        admins = [];
        snapshot.docs
          .filter((doc) => user != null && doc.id !== user.uid)
          .forEach((doc) => {
            const user: any = { ...doc.data(), uid: doc.id };
            admins.push(
              createData(user.name, user.email, user.uid)
            );
          });
        setRows(admins);
      });
    };
    fetchAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3>Add a new Admin</h3>
      <form className="Profile-form">
        <TextField
          className="Profile-form-input"
          id="name"
          label="Name"
          error={false}
          variant="outlined"
          size="small"
          value={name} 
          onChange={(event?) =>
            event != null ? setName(event.target.value) : ""
          }
          style={{ paddingBottom: "0.5rem" }}
        />
        <TextField
          className="Profile-form-input"
          id="email"
          label="Admin email"
          variant="outlined"
          size="small"
          value={email} 
          onChange={(event?) =>
            event != null ? setEmail(event.target.value) : ""
          }
          style={{ paddingBottom: "0.5rem" }}
        />
        <TextField
          className="Profile-form-input"
          id="password"
          label="Password"
          variant="outlined"
          size="small"
          value={password} 
          onChange={(event?) =>
            event != null ? setPassword(event.target.value) : ""
          }
        />
      </form>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          await createNewAdmin(name, email, password);
          setName('');
          setEmail('');
          setPassword('');
        }}
      >
        Add Admin
      </Button>
      <h3>Current Admins</h3>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Edit Account</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows !== [] || rows !== null ? (
              rows.map((row: any) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      {<Button onClick={() => setRemoving(row)}>Remove</Button>}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow key={1}>
                <TableCell>{"no data"}</TableCell>
                <TableCell>{"no data"}</TableCell>
                <TableCell>{"no data"}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        className={classes.modal}
        open={removing.id.length !== 0}
        onClose={() => setRemoving(nullAdmin)}
      >
        <div className={classes.paper}>
          <h2>Are you sure about the removal?</h2>
          <p>
            Please type <b className={classes.focused}> {removing.name}</b> to
            confirm this removal for account:
          </p>
          <div className={classes.center}>
            <b>{removing.id}</b>
          </div>
          <TextField
            id="confirmName"
            variant="outlined"
            size="small"
            autoComplete="off"
            fullWidth
            onChange={(event?) =>
              event != null ? setConfirmName(event.target.value) : ""
            }
          />
          <div className={classes.row}>
            <Button
              className={classes.confirmRemovalButton}
              disabled={confirmName !== removing.name}
              onClick={() =>
                removeAdmin(removing.id, () => setRemoving(nullAdmin))
              }
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <div className={classes.center}>
        <TablePagination
          component="div"
          count={100}
          page={0}
          onChangePage={() => {}}
          rowsPerPage={10}
          onChangeRowsPerPage={() => {}}
        />
      </div>
    </div>
  );
}

// Create
const createNewAdmin = async function (
  name: string,
  email: string,
  password: string
) {
  const user: firebase.auth.UserCredential | null = await createUser(
    email,
    password
  );
  if (user !== null && user.user !== null) {
    const adminUser = {
      name: name,
      email: email,
      uid: user.user.uid,
    };
    await adminRef.doc(user.user.uid).set(adminUser);
  }
};

// Remove
const removeAdmin = async function (uid: string, dismiss: Function) {
  firebase
    .auth()
    .currentUser?.getIdToken()
    .then((token) => {
      if (token !== undefined) {
        const decode: AdminLevel = jwt_decode(token) as AdminLevel;
        if (
          decode["superAdmin"] !== undefined &&
          decode["superAdmin"] === true
        ) {
          adminRef.doc(uid).delete();
          dismiss();
        }
      }
    });
};

export default withStyles(styles)(AdminsPage);
