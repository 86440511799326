export const bookingsSortOptions = {
    // id: "ID",
    startTime: "Date",
    began: "Began",
    finished: "Finished"
};

export const beauticiansSortOptions = {
    blocked: "Blocked",
    // uid: "UID",
    displayName: "Name",
    email: "Email",
}

export const applicantsSortOptions = {
    // uid: "UID",
    displayName: "Name",
    email: "Email",
}