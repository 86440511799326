import { DocumentData } from '@firebase/firestore-types';

export default interface Appointment {
    arrivalInstructions: string;
    began: boolean;
    finished: boolean;
    proId: string;
    proDisplayName: string;
    userId: string;
    userDisplayName: string;
    id: string;
    photos: string[];
    startTime: Date;
    schedule: ServiceSchedule;
    traveling: boolean;
    service: Service;
    additionsTotalCost: number;
    serviceTotalCost: number;
    tip: number;
    location: Location;
    specialty: string;
}

export function createAppointment(data: DocumentData): Appointment {
    return {
        arrivalInstructions: data.arrivalInstructions,
        began: data.began,
        finished: data.finished,
        proId: data.proId,
        proDisplayName: data.proDisplayName,
        userId: data.userId,
        userDisplayName: data.userDisplayName,
        id: data.id,
        photos: data.photos,
        startTime: data.startTime != null ? data.startTime.toDate() : null,
        schedule: data.schedule,
        traveling: data.traveling,
        service: data.service,
        additionsTotalCost: data.additionsTotalCost,
        serviceTotalCost: data.serviceTotalCost,
        tip: data.tip,
        location: data.location,
        specialty: data.specialty,
    };
}

interface Location {
    formattedAddress: string;
    placeId: string;
    geohash: string;
    geopoint: Map<string, any>;
}

interface ServiceSchedule {
    hours: number[];
    working: boolean;
}

export interface Service {
    label: string;
}
