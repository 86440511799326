import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import "firebase/auth";
import {
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import "firebase/firestore";
import Account, { createAccount } from "../interfaces/account";
import { useHistory } from 'react-router-dom';
import * as ROUTES from "../constants/routes";
import { applicantsSortOptions } from "../interfaces/sortOptions";
import { usersRef } from "../services/firebase";
import Pagination from "./AdminsPage/Pagination";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import React from "react";
import { FirebaseContext } from "../components/Firebase";
import { FirebaseFirestore, QuerySnapshot } from '@firebase/firestore-types';

const styles = (theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 650,
    },
    button: {
      backgroundColor: "#FDC032",
      color: "#000",
      "&:hover": {
        backgroundColor: "#FDC032",
        color: '#000'
      }
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  });

export interface ContentProps extends WithStyles<typeof styles> { }

function ApplicantsPage(props: ContentProps) {
  const { classes } = props;
  const history = useHistory();
  const [field, setField] = useState<any>(Object.keys(applicantsSortOptions)[0]);
  const db = useContext(FirebaseContext) as FirebaseFirestore;
  const [,setLoading] = useState(true);
  const [beauticians, setBeauticians] = useState<Array<Account>>([]);

  useEffect(() => {
    const bookings = usersRef.where('mode', '==', 'professional').where('phoneNumberVerified', '==', true).where('licenseVerified', '==', false)
      .onSnapshot((snapshot: QuerySnapshot) => {
        if (snapshot.size) {
          setBeauticians(snapshot.docs.map((doc) => createAccount(doc.data())));
          setLoading(false);
        }
        else {
          setLoading(false);
        }
      });
    return () => {
      bookings();
    }
  }, [db]);

  return (
    <div>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="age-native-simple">Sort By</InputLabel>
          <Select
            native
            value={field}
            onChange={(e) => setField(e.target.value)}
          >
            {Object.entries(applicantsSortOptions).map(([key, value]) => <option key={key} value={key}>{value}</option>)}
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell>Beautician Id</TableCell> */}
              <TableCell align="right">Beautician Name</TableCell>
              <TableCell align="right">Phone Number</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Location</TableCell>
              <TableCell align="right">View Application</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {beauticians.map((beautician) => (
              <TableRow key={beautician.uid}>
                {/* <TableCell component="th" scope="row">
                  {beautician.uid}
                </TableCell> */}
                <TableCell align="right">{beautician.displayName}</TableCell>
                <TableCell align="right">{beautician.phoneNumber}</TableCell>
                <TableCell align="right">{beautician.email}</TableCell>
                <TableCell align="right">{beautician.location.formattedAddress}</TableCell>
                <TableCell align="right">
                  <Button className={classes.button} onClick={() => {
                    history.push(`/home${ROUTES.APPLICANTS}/${beautician.uid}/${beautician.displayName}`);
                  }}>
                    Review Application
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        collection={usersRef.where('mode', '==', 'professional').where('licenseVerified', '==', false)}
        setLoading={setLoading}
        setData={setBeauticians}
        field={field}
        descendingFields={[]}
        createObject={createAccount} />
    </div>
  );
}

export default withStyles(styles)(ApplicantsPage);