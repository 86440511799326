import React, { useState, useEffect } from "react";
import { constantsRef } from "../../services/firebase";
import { Button } from "@material-ui/core";
import { TextEditor } from "../TextEditor";

export const EditorContainer = (props: any) => {
  const [header] = useState(props.title);
  const [field] = useState(props.id);
  const [constants, setConstants] = useState({
    appFee: 0,
    privacyPolicy: "",
    rangeInMiles: 0,
    stripeConnect: "",
    stripeKey: "",
    termsConditions: "",
  });
  const [content, setContent] = useState("");
  const [canEdit, setCanEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      const querySnapshot = await constantsRef.get();
      querySnapshot.docs.forEach((doc) => {
        const document: any = { ...doc.data() };
        setConstants(document);
        setContent(JSON.stringify(document[field]));
      });
    };
    fetchContent();
  }, [field]);

  const edit = () => {
    setCanEdit(!canEdit);
    setIsDisabled(!isDisabled);
  };

  const contentChanged = (document: string) => {
    setContent(document);
  };

  const save = () => {
    const newConstants: any = { ...constants };
    let value;
    try {
      value = JSON.parse(content);
    } catch (err) {
      if (field !== 'categories' && field !== 'faq') {
        value = content;
      } else if (field === 'appFee') {
        value = Number.parseFloat(content);
      }
    }
    if (value !== undefined && value !== null) {
      newConstants[field] = value;
      constantsRef.doc("constants").set(newConstants);
    }
    setCanEdit(!canEdit);
    setIsDisabled(!isDisabled);
  };

  return (
    <div>
      <div className="Policy-header">
        <h3>{header}</h3>
        <Button onClick={() => (canEdit === true ? save() : edit())}>
          {canEdit === false ? "Edit" : "Save"}
        </Button>
      </div>
      <TextEditor
        disabled={isDisabled}
        title={header}
        save={contentChanged}
        content={content}
      />
    </div>
  );
};
