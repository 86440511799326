import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
} from "@material-ui/core/styles";
import "firebase/auth";
import {
    TableContainer,
    TableHead,
    Table,
    TableCell,
    TableRow,
    TableBody,
} from "@material-ui/core";
import "firebase/firestore";
import Appointment, { createAppointment } from "../interfaces/appointment";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { bookingsSortOptions } from "../interfaces/sortOptions";
import { appointmentsRef } from "../services/firebase";
import Pagination from "./AdminsPage/Pagination";

const styles = (theme: Theme) =>
    createStyles({
        table: {
            // minWidth: 650,
        },
        center: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
    });

export interface ContentProps extends WithStyles<typeof styles> { }

function BookingsPage(props: ContentProps) {
    const { classes } = props;
    const [bookings, setBookings] = useState<Array<Appointment>>([]);
    // show size of query in page navigation component
    const [field, setField] = useState<any>(Object.keys(bookingsSortOptions)[0]);
    const descendingFields = ['began', 'finished', 'startTime'];

    return (
        <div>
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="age-native-simple">Sort By</InputLabel>
                    <Select
                        native
                        value={field}
                        onChange={(e) => setField(e.target.value)}
                    >
                        {Object.entries(bookingsSortOptions).map(([key, value]) => <option key={key} value={key}>{value}</option>)}
                    </Select>
                </FormControl>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">Customer</TableCell>
                            <TableCell align="right">Beautician</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="right">Service</TableCell>
                            <TableCell align="right">Began</TableCell>
                            <TableCell align="right">Finished</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bookings.map((booking) => (
                            <TableRow key={booking.id}>
                                <TableCell align="right">{booking.userDisplayName}</TableCell>
                                <TableCell align="right">{booking.proDisplayName}</TableCell>
                                <TableCell align="right">{booking.startTime == null ? "No Start Time" : `${booking.startTime.toLocaleTimeString()} on ${booking.startTime.toDateString()}`}</TableCell>
                                <TableCell align="right">{booking.service.label}</TableCell>
                                <TableCell align="right">{booking.began == null ? "Not set" : booking.began ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="right">{booking.finished == null ? "Not set" : booking.finished ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                field={field}
                collection={appointmentsRef}
                setLoading={() => {}}
                setData={setBookings}
                setConstants={() => {}}
                descendingFields={descendingFields}
                createObject={createAppointment} />
        </div>
    );
}

export default withStyles(styles)(BookingsPage);