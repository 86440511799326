import firebase from "firebase/app";
import "firebase/firestore";
import { INotificationForm } from "../pages/NotificationsPage";
import { QuerySnapshot, DocumentSnapshot } from '@firebase/firestore-types';
import Constants, { createConstants } from "../interfaces/constants";

export const firebaseConfig = {
  apiKey: "AIzaSyDZBEw_9LxOlDyOmDdVvHjNM3FzxA1d3Mw",
  authDomain: "glamzzy-4618b.firebaseapp.com",
  projectId: "glamzzy-4618b",
  storageBucket: "glamzzy-4618b.appspot.com",
  messagingSenderId: "29668215663",
  appId: "1:29668215663:web:91e29fa9f65a0df8ccf69a",
  measurementId: "G-09ND3592WE"
};
// DEV CONFIG
// {
//   apiKey: "AIzaSyDZLZk7v7OPHe6dRr5bhm20CiDA7g7CQ4k",
//   authDomain: "glamzzy-dev-a3b48.firebaseapp.com",
//   projectId: "glamzzy-dev-a3b48",
//   storageBucket: "glamzzy-dev-a3b48.appspot.com",
//   messagingSenderId: "488766196128",
//   appId: "1:488766196128:web:579f3f9f394c22af867cc5",
//   measurementId: "G-102WFGR1RW"
// };

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export const adminRef = db.collection('admins');
export const constantsRef = db.collection('constants');
export const appointmentsRef = db.collection('appointments');
const notificationsRef = db.collection('notifications');
export const usersRef = db.collection('users');
export const profilesRef = db.collection('profiles');

export const createNotification = async (notificationForm: INotificationForm): Promise<boolean> => {
  let success = false;
  const adminUid = firebase.auth().currentUser?.uid;
  await notificationsRef.doc()
    .set({
      ...notificationForm,
      createdBy: adminUid,
      createdAt: new Date(),
      complete: false,
      canceled: false,
      numNotified: null
    });
  success = true;
  return success;
}

export const bookingsCount = (callback: (count: number) => void): () => void => {
  return appointmentsRef
    .onSnapshot((snapshot: QuerySnapshot) => {
      callback(snapshot.docs.length);
    });
}

export const bookingsOrderByLimit = (callback: (snapshot: QuerySnapshot) => void, field: string, pageSize: number, descFields: Array<string>): () => void => {
  return !descFields.includes(field) ? appointmentsRef
    .orderBy(field)
    .limit(pageSize)
    .onSnapshot((snapshot: QuerySnapshot) => {
      callback(snapshot);
    }) : bookingsOrderByDesc(callback, field, pageSize)
}

export const bookingsOrderByDesc = (callback: (snapshot: QuerySnapshot) => void, field: string, pageSize: number): () => void => {
  return appointmentsRef
    .orderBy(field, 'desc')
    .limit(pageSize)
    .onSnapshot((snapshot: QuerySnapshot) => {
      callback(snapshot);
    })
}

export const bookingsNextPage = (callback: (snapshot: QuerySnapshot) => void, descFields: Array<String>, field: string, pageSize: number, endBefore: string) => {
  return descFields.includes(field) ?
    bookingsNextPageOrderByDesc(callback, field, pageSize, endBefore)
    :
    bookingsNextPageOrderBy(callback, field, pageSize, endBefore)
}

export const bookingsPreviousPage = (callback: (snapshot: QuerySnapshot) => void, descFields: Array<String>, field: string, pageSize: number, startAfter: string) => {
  return descFields.includes(field) ?
    bookingsPreviousPageOrderByDesc(callback, field, pageSize, startAfter)
    :
    bookingsPreviousPageOrderBy(callback, field, pageSize, startAfter)
}


export const bookingsNextPageOrderBy = (callback: (snapshot: QuerySnapshot) => void, field: string, pageSize: number, prevLast: string): () => void => {
  return appointmentsRef
    .orderBy(field, 'desc')
    .startAfter(prevLast).limit(pageSize)
    .onSnapshot((snapshot: QuerySnapshot) => {
      callback(snapshot);
    })
}

export const bookingsNextPageOrderByDesc = (callback: (snapshot: QuerySnapshot) => void, field: string, pageSize: number, prevLast: string): () => void => {
  return appointmentsRef
    .orderBy(field, 'desc')
    .startAfter(prevLast).limit(pageSize)
    .onSnapshot((snapshot: QuerySnapshot) => {
      callback(snapshot);
    })
}

export const bookingsPreviousPageOrderBy = (callback: (snapshot: QuerySnapshot) => void, field: string, pageSize: number, prevFirst: string): () => void => {
  return appointmentsRef
    .orderBy(field)
    .endBefore(prevFirst).limit(pageSize)
    .onSnapshot((snapshot: QuerySnapshot) => {
      callback(snapshot);
    })
}

export const bookingsPreviousPageOrderByDesc = (callback: (snapshot: QuerySnapshot) => void, field: string, pageSize: number, prevFirst: string): () => void => {
  return appointmentsRef
    .orderBy(field, 'desc')
    .endBefore(prevFirst).limit(pageSize)
    .onSnapshot((snapshot: QuerySnapshot) => {
      callback(snapshot);
    })
}

export const fetchConstants = (setConstants: (constants: Constants) => void, setLoading: (value: boolean) => void) => {
  return constantsRef.doc('constants').onSnapshot((snapshot: DocumentSnapshot) => {
    const docData = snapshot.data()
    if (docData !== undefined) {
      setConstants(createConstants(docData));
      setLoading(false);
    }
    else {
      setLoading(false);
    };
  });
}

export default db;