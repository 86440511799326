import React, { useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import {
  FormControl,
  Select,
  MenuItem,
  Card,
  Button,
  CardActions,
  Typography,
  CardContent,
  TextField,
  Box,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import SendIcon from "@material-ui/icons/Send";
import { createNotification } from "../services/firebase";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      "& .MuiTextField-root": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      "& .MuiOutlinedInput-root": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      [theme.breakpoints.up("xs")]: {
        minWidth: "70vw",
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: "60vw",
      },
      [theme.breakpoints.up("md")]: {
        minWidth: "50vw",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: "40vw",
      },
    },
  });

export interface ContentProps extends WithStyles<typeof styles> {}

export interface INotificationForm {
  userGroup: string;
  title: string;
  body: string;
  sendDate: Date;
}

function ApplicantsPage(props: any) {
  const { classes } = props;

  const [userGroup, setUserGroup] = useState<string>("beauticians"); // 'none'
  const [title, setTitle] = useState<string>("This is a test"); // ''
  const [body, setBody] = useState<string>("Sending a great notification"); // ''
  const [sendDate, setSendDate] = useState<Date>(new Date());

  const handleInputChange = (e: any) => {
    if (e !== null) {
      if (typeof e.getMonth === "function") {
        setSendDate(new Date(e));
      } else {
        if (e.target.name === "userGroup") {
          setUserGroup(e.target.value);
        } else if (e.target.name === "title") {
          setTitle(e.target.value);
        } else if (e.target.name === "body") {
          setBody(e.target.value);
        }
      }
    }
  };

  const sendNotification = async () => {
    const success = await createNotification({
      userGroup,
      title,
      body,
      sendDate,
    });
    if (success) {
      setUserGroup("none");
      setTitle("");
      setBody("");
      setSendDate(new Date());
    } else {
      // TODO: show error toast
    }
  };

  const isFormValid = (): boolean => {
    let valid = true;
    if (
      userGroup === "none" ||
      title.length === 0 ||
      title.length > 50 ||
      body.length === 0 ||
      body.length > 175
    ) {
      valid = false;
    }
    return valid;
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h6">Create Notification</Typography>
          <FormControl className={classes.formControl} variant="outlined">
            <Select
              labelId="user-group-label"
              id="user-group-select"
              name="userGroup"
              value={userGroup}
              onChange={handleInputChange}
            >
              <MenuItem value="none" disabled>
                User Group
              </MenuItem>
              <MenuItem value={"beauticians"}>Beauticians</MenuItem>
              <MenuItem value={"clients"}>Clients</MenuItem>
              <MenuItem value={"everyone"}>Everyone</MenuItem>
            </Select>
            <TextField
              variant="outlined"
              id="title"
              label={"Title (" + title.length + "/50)"}
              InputLabelProps={{
                shrink: true,
              }}
              name="title"
              multiline
              rows={1}
              rowsMax={2}
              value={title}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              variant="outlined"
              id="body"
              label={"Body (" + body.length + "/175)"}
              InputLabelProps={{
                shrink: true,
              }}
              name="body"
              multiline
              rows={4}
              rowsMax={4}
              value={body}
              onChange={handleInputChange}
              inputProps={{ maxLength: 175 }}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker"
                label="Send Date"
                name="sendDate"
                value={sendDate}
                onChange={handleInputChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Send Time"
                name="sendDate"
                value={sendDate}
                onChange={handleInputChange}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!isFormValid()}
              endIcon={<SendIcon fontSize="small" />}
              onClick={() => sendNotification()}
            >
              Send
            </Button>
          </CardActions>
        </Box>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(ApplicantsPage);
