import { makeStyles } from "@material-ui/core/styles";
import { colors } from "./constants";

// Override Material Textfield
export const textFieldStyle = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.secondary
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.secondary
    },
    '&:hover .MuiInput-underline:before': {
      borderBottomColor: colors.secondary
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.secondary,
      },
      '&:hover fieldset': {
        borderColor: colors.secondary
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.secondary
      },
    },
  },
});