export const LOGIN = '/';
export const DASHBOARD = '/home';
export const APPLICANTS = '/applicants';
export const BEAUTICIANS = '/beauticians';
export const PRICING = '/pricing';
export const BOOKINGS = '/bookings';
export const POLICIES = '/policies';
export const NOTIFICATIONS = '/notifications';
export const ADMINS = '/admins';
export const APPLICATION = '/applications';
