import { DocumentData } from '@firebase/firestore-types';

export default interface Account {
    uid: string;
    blocked: string;
    displayName: string;
    phoneNumber: string;
    email: string;
    photoUrl: string;
    profileUrl: string;
    phoneNumberVerified: boolean;
    emailVerified: boolean;
    acceptedTerms: boolean;
    allowNotifications: boolean;
    allowSMS: boolean;
    disabled: boolean;
    createdAt: Date;
    signInMethod: string;
    mode: string;
    licenses: any[];
    location: LocationModel;
    licenseVerified: boolean;
    chargedBy: boolean;
    about: string;
    fcmToken: string;
}

export interface LocationModel {
    formattedAddress: string;
    placedId: string;
    geohash: string;
    geopoint: Map<string, any>;
}

export function createAccount(data: DocumentData): Account {
    return {
        uid: data.uid,
        blocked: data.blocked,
        displayName: data.displayName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        photoUrl: data.photoUrl,
        profileUrl: data.photoUrl,
        phoneNumberVerified: data.phoneNumberVerified === undefined ? false : true,
        emailVerified: data.emailVerified === undefined ? false : data.emailVerified,
        acceptedTerms: data.acceptedTerms,
        allowNotifications: data.allowNotifications === undefined ? false : data.allowNotifications,
        allowSMS: data.allowSMS === undefined ? false : data.allowSMS,
        disabled: data.disabled,
        createdAt: data.createdAt,
        signInMethod: data.signInMethod,
        mode: data.mode,
        licenses: data.licenses,
        location: data.location,
        licenseVerified: data.licenseVerified,
        chargedBy: data.chargedBy,
        about: data.about,
        fcmToken: data.fcmToken,
    };
}