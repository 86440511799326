import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import NotFound from "./pages/NotFound";
import * as ROUTES from "./constants/routes";
import { ProtectedRoute } from "./components/ProtectedRoute";
import firebase, { User } from "firebase/app";
import "firebase/auth";

function App() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const getAuthStatus = () => {
      firebase.auth().onAuthStateChanged((resp) => {
        setUser(resp);
      });
    };
    getAuthStatus();
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={ROUTES.LOGIN}
        render={(props) => <LoginPage {...props} auth={user} />}
      />
      ;
      <ProtectedRoute
        path={ROUTES.DASHBOARD}
        component={DashboardPage}
        {...{ auth: user }}
      />
      ;
      <Route path="*" component={NotFound} />;
    </Switch>
  );
}

export default App;
