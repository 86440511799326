import React from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import * as ROUTES from "../../constants/routes";

interface ProtectedRouteProps extends RouteProps {
  path: string;
  auth: any;
}

export function ProtectedRoute(props: ProtectedRouteProps) {
  const {auth, component, render} = props; // path
  return (
    <Route
      render={(props: RouteComponentProps) => {
        if (auth === null) {
          return <Redirect to={ROUTES.LOGIN} />;
        }

        if (component) {
          return React.createElement(component);
        }

        if (render) {
          return render(props);
        }
      }}
    />
  );
}
