import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
} from "@material-ui/core";
import { authenticate } from "../../services/auth";
import { textFieldStyle } from "../../constants/styles";

function LoginForm(props: any) {
  const { history } = props;
  const classes = textFieldStyle();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const login = async () => {
    const success = await authenticate(email, password);
    if (success) {
      history.push("/home/applicants");
    }
  };

  return (
    <Box
      style={{
        backgroundColor: "white",
        margin: "auto",
        width: "456px",
        height: "350px",
        borderRadius: "5%",
        marginBottom: "40vh",
      }}
    >
      <form
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 30,
          paddingRight: 30,
          height: "300px",
        }}
      >
        <h1 style={{ marginBottom: 30, letterSpacing: 0.7 }}>
          {"Glamzzy Admin Login"}
        </h1>
        <>
          <TextField
            className={classes.root}
            type="text"
            label="Email"
            variant="outlined"
            autoComplete="username"
            style={{ height: 37 }}
            fullWidth
            value={email}
            onChange={(event?) =>
              event != null ? setEmail(event.target.value) : ""
            }
          />
          <TextField
            className={classes.root}
            type="password"
            label="Password"
            autoComplete="current-password"
            variant="outlined"
            style={{ height: 37, marginTop: 30 }}
            fullWidth
            value={password}
            onChange={(event?) =>
              event != null ? setPassword(event.target.value) : ""
            }
          />
        </>
        <Button
          variant="contained"
          color="primary"
          id="sign-in-button"
          onClick={() => (login())}
          fullWidth
          style={{
            fontWeight: "bold",
            marginTop: 30,
            height: 37,
            background:
              "linear-gradient(113deg, rgba(253, 192, 44, 1) 0%, rgba(136, 109, 48, 1) 100%)",
          }}
        >
          {"Log In"}
        </Button>
      </form>
    </Box>
  );
}

export default LoginForm;
