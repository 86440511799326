import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import "firebase/auth";
import "firebase/firestore";
import { useEffect, useState } from "react";
import {
  CollectionReference,
  QuerySnapshot,
  DocumentSnapshot,
  Query,
  DocumentData,
} from "@firebase/firestore-types";
import Constants, { createConstants } from "../../interfaces/constants";
import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import { constantsRef } from "../../services/firebase";

const styles = (theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 650,
    },
    center: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  });

export interface ContentProps extends WithStyles<typeof styles> {
  collection: CollectionReference | Query<DocumentData>;
  setLoading: (value: React.SetStateAction<boolean>) => void;
  setData: (value: React.SetStateAction<Array<any>>) => void;
  setConstants?: (value: React.SetStateAction<Constants | undefined>) => void;
  field: string;
  descendingFields: Array<string>;
  createObject: Function;
}

function Pagination(props: ContentProps) {
  const {
    classes,
    collection,
    setLoading,
    setData,
    setConstants,
    descendingFields,
    createObject,
    field,
  } = props;
  // set current query start in pagination of field value
  const [prevFirst, setPrevFirst] = useState<string>("");
  // set current query end in pagination of field value
  const [prevLast, setPrevLast] = useState<string>("");
  // get the last field value of current query
  const [last, setLast] = useState<string>("");
  // get the first field value of current query
  const [first, setFirst] = useState<string>("");
  const [pageSize, setPageSize] = useState(10);
  // page index
  const [page, setPage] = useState<number>(0);
  const [dataCount, setDataCount] = useState<number>(0);
  const [isNext, setIsNext] = useState<boolean>(true);

  const handlePages = async (snapshot: QuerySnapshot) => {
    // set the current first
    if (snapshot.docs[0] !== undefined) {
      setFirst(snapshot.docs[0].id);
    }
    // set the current Last
    if (snapshot.docs[snapshot.docs.length - 1] !== undefined) {
      setLast(snapshot.docs[snapshot.docs.length - 1].id);
    }
    // set bookings to display for this page
    if (snapshot.size) {
      setData(snapshot.docs.map((doc) => createObject(doc.data())));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  let unsubscribeData = () => {};
  let unsubscribeDataCount = () => {};
  let unsubscribeConstants = () => {};

  useEffect(() => {
    unsubscribeData();
    unsubscribeDataCount();
    unsubscribeConstants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    unsubscribeDataCount = props.collection.onSnapshot(
      (snapshot: QuerySnapshot) => {
        setDataCount(snapshot.docs.length);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    unsubscribeConstants = constantsRef
      .doc("constants")
      .onSnapshot((snapshot: DocumentSnapshot) => {
        const docData = snapshot.data();
        if (setConstants !== undefined && docData !== undefined) {
          setConstants(createConstants(docData));
          setLoading(false);
        } else {
          setLoading(false);
        }
      });

    unsubscribeData =
      // eslint-disable-next-line react-hooks/exhaustive-deps
      prevFirst === "" || prevLast === ""
        ? descendingFields.includes(field)
          ? collection
              .orderBy(field, "desc")
              .limit(pageSize)
              .onSnapshot((snapshot: QuerySnapshot) => {
                handlePages(snapshot);
              })
          : collection
              .orderBy(field)
              .limit(pageSize)
              .onSnapshot((snapshot: QuerySnapshot) => {
                handlePages(snapshot);
              })
        : isNext
        ? descendingFields.includes(field)
          ? collection
              .orderBy(field, "desc")
              .startAfter(prevLast)
              .limit(pageSize)
              .onSnapshot((snapshot: QuerySnapshot) => {
                handlePages(snapshot);
              })
          : collection
              .orderBy(field)
              .startAfter(prevLast)
              .limit(pageSize)
              .onSnapshot((snapshot: QuerySnapshot) => {
                handlePages(snapshot);
              })
        : descendingFields.includes(field)
        ? collection
            .orderBy(field, "desc")
            .endBefore(prevFirst)
            .limitToLast(pageSize)
            .onSnapshot((snapshot: QuerySnapshot) => {
              handlePages(snapshot);
            })
        : collection
            .orderBy(field)
            .endBefore(prevFirst)
            .limitToLast(pageSize)
            .onSnapshot((snapshot: QuerySnapshot) => {
              handlePages(snapshot);
            });
    return () => {
      // stop listening when leaving view
      unsubscribeConstants();
      unsubscribeDataCount();
      unsubscribeData();
    };
  }, [prevFirst, prevLast, isNext, pageSize, page, field]);

  return (
    <div className={classes.center}>
      <TablePagination
        component="div"
        count={dataCount}
        page={page}
        onChangePage={(e, newPage) => {
          setIsNext(newPage > page);
          setPage(newPage);
          setPrevLast(last);
          setPrevFirst(first);
        }}
        rowsPerPage={pageSize}
        onChangeRowsPerPage={(e) => {
          setPageSize(+e.target.value);
        }}
      />
    </div>
  );
}

export default withStyles(styles)(Pagination);
