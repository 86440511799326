import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import LoginForm from "../components/LoginForm";
import { withRouter } from "react-router-dom";
import { User } from "firebase";

const styles = (theme: Theme) =>
  createStyles({
    gradientBtn: {
      fontSize: 15,
      color: "white",
      fontWeight: "bold",
      marginTop: 10,
      width: 300,
      height: "37px",
      background:
        "linear-gradient(113deg, rgba(247, 235, 185, 1) 0%, rgba(253, 192, 44, 1) 100%)",
    },
    text: {
      fontSize: 24,
      width: 600,
      lineHeight: 1.3,
    },
    solidBtn: {
      fontSize: 15,
      color: "white",
      fontWeight: "bold",
      letterSpacing: 0.8,
      backgroundColor: "black",
      marginTop: 10,
      "&:hover": {
        backgroundColor: "black",
      },
    },
    offSet: {
      marginLeft: 200,
    },
  });

function Login(props: any) {
  const { history } = props;
  const [auth] = useState<User>(props.auth);

  useEffect(() => {
    const checkIfAuth = () => {
      if (auth !== null && auth !== undefined) {
        history.push("/home/applicants");
      }
    };
    checkIfAuth();
  }, [auth, history]);

  useEffect(() => {
    if (props.auth !== null && props.auth !== undefined) {
      history.push("/home/applicants");
    }
  }, [props.auth, auth, history]);

  return (
    <Grid
      container
      justify="flex-start"
      style={{
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        width: "100%",
        position: "relative",
        backgroundColor: "#000",
      }}
    >
      <LoginForm {...props} />
    </Grid>
  );
}

export default withStyles(styles)(withRouter(Login));
