import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";

export const TextEditor = (props: any) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  useEffect(() => {}, [props.disabled]);

  const config = {
    readonly: props.disabled, // all options from https://xdsoft.net/jodit/doc/
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      onBlur={(newContent) => {
        setContent(newContent);
        props.save(newContent);
      }}
      onChange={(newContent) => {}}
    />
  );
};
