import React from "react";
import "firebase/auth";
import { EditorContainer } from "../../components/EditorContainer";
import "./PoliciesPage.css";

export const PoliciesPage = () => {
  return (
    <div>
      <EditorContainer title={"Terms & Conditions"} id={'termsConditions'} />
      <EditorContainer title={"Privacy Policy"} id={'privacyPolicy'} />
    </div>
  );
};

export default PoliciesPage;
